<template>
  <div>
    <!-- Button   -->
    <div v-if="transcript && crmFields.length"
         class="crm-auto-detect-btn"
         type="button"
    >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-b-tooltip.hover="" :title="staticText.autoDetectCrmDataTooltip" @click="showCRMFieldDetectionModal=true" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.354 2.146a.5.5 0 00-.708 0l-2.438 2.439 3.207 3.207 2.439-2.438a.5.5 0 000-.708l-2.5-2.5zM14.707 8.5L11.5 5.293l-9.354 9.353a.5.5 0 000 .708l2.5 2.5a.5.5 0 00.708 0L14.707 8.5z" fill="#7F8197"/><path class="wand" d="M7 2.5a2 2 0 002 2 2 2 0 00-2 2 2 2 0 00-2-2 2 2 0 002-2zM15.5 11a2 2 0 002 2 2 2 0 00-2 2 2 2 0 00-2-2 2 2 0 002-2z" fill="#7F8197" stroke="#7F8197" stroke-linecap="round" stroke-linejoin="round"/></svg>
    </div>

    <!--Modal-->
    <b-modal v-model="showCRMFieldDetectionModal"
             :title="staticText.crmDataDetectedTitle"
             :ok-title="staticText.saveButtonLabel"
             footer-class="d-flex justify-content-end"
             size="lg"
             @show="handleModalOpen"
             @ok="saveSuggestedCRMData"

    >
      <ProgressIndicator v-if="loading"/>
      <div v-else>
        <div v-if="!crmDataDetected">
          {{ staticText.noDataDetectedMessage }}
        </div>
        <div v-else>
          <div class="mb-3">{{ staticText.dataDetectedMessage }}</div>
          <div v-for="(crmItem, index) in crmItems"
               :key="index"
          >
            <CrmFieldList
              v-if="crmItem.suggestedAnswers && crmItem.suggestedAnswers.length>0"
              :item-id="`auto-detect-modal-${crmItem.uniqueId}`"
              :playbook-item="crmItem"
              :show-icons="false"
              :is-open-from-call-wrap-up="true"
              @input="value => handleCRMItemUpdated(value, crmItem)"
            ></CrmFieldList>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios"
import { mapActions, mapGetters } from "vuex"
import ProgressIndicator from "@/apps/base/ProgressIndicator"
import { typeServices } from "@/apps/call"
import CrmFieldList from "@/apps/call/CallViewItem_components/CrmFieldList"

export default {
  name: "TranscriptCRMFieldsPreSelection",
  components: { ProgressIndicator, CrmFieldList },

  data () {
    return {
      axios,
      crmItems: [],
      itemsWithAnswersToSave: [],
      loading: false,
      loadingCompleted: false,
      showCRMFieldDetectionModal: false,
      detectedFields: null,
      staticTextDefault: {
        crmDataDetectedTitle: "CRM-Data Detected",
        saveButtonLabel: "Save",
        autoDetectCrmDataTooltip: "Prefill AI detected CRM-Data from Transcript",
        noDataDetectedMessage: "Unfortunately no matching data could be found.",
        dataDetectedMessage: "Please check if following data is detected correctly. You can correct it here right away, if modifications are needed."
      }
    }
  },

  computed: {
    ...mapGetters({
      call: "callAudioStore/getCall",
      playbookItems: "callSummaryStore/getPlaybookItems"

    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    crmFields () {
      if (this.crmItems) {
        return this.crmItems
          .flatMap(crmItem => crmItem.linked_field.field_definition.map(def => (
            {
              question_id: def.id,
              key: def.label,
              value_type: this.getValueType(def.type),
              can_have_multiple_values: def.fieldType === "checkbox"
            }
          )))
      }
      return []
    },
    transcript () {
      if (this.call && this.call.transcription && this.call.transcription.response_data) {
        return this.call.transcription.response_data.map(item => item.text).join(".")
      }

      return null
    },
    crmDataDetected () {
      return this.crmItems.some(item => item && item.suggestedAnswers && item.suggestedAnswers.length > 0)
    }
  },

  watch: {
    playbookItems: {
      immediate: true,
      handler (val) {
        this.crmItems = val.filter(item => item.item_type === "crmlink" && item.linked_field)
      }
    }
  },

  methods: {
    ...typeServices,
    ...mapActions({
      handleCallItemChanged: "callSummaryStore/handleCallItemChanged",
      fetchCallSummary: "callSummaryStore/fetchCallSummary"
    }),
    async saveSuggestedCRMData () {
      // save suggested data plus edited data
      try {
        for (const crmItem of this.crmItems) {
          const itemWithAnswers = this.itemsWithAnswersToSave.find(item => item && item.item_id === crmItem.id)
          if (itemWithAnswers) {
            itemWithAnswers.answersToSave.forEach(answer => {
              if (answer.text) {
                if (typeof answer.text === "object") {
                  answer.text = answer.text.value.toString()
                }
                answer.text = answer.text.toString()
              } else {
                answer.text = null
              }
            })

            const existingAnswers = crmItem.call_item && crmItem.call_item.answers ? crmItem.call_item.answers : []

            itemWithAnswers.answersToSave.forEach(ans => {
              const existingAnswerIndex = existingAnswers.findIndex(i => i && i.question_id === ans.question_id)
              if (existingAnswerIndex > -1) {
                existingAnswers.splice(existingAnswerIndex, 1)
              }
            })

            itemWithAnswers.answersToSave.forEach(answerToSave => existingAnswers.push(answerToSave))
            crmItem.selectedAnswers = existingAnswers
          }
          await this.handleCallItemChanged({ item: crmItem, callId: this.call.call_id })
          crmItem.suggestedAnswers = null // reset suggested answers
        }
      } catch (e) {
        console.error(e)
      } finally {
        await this.fetchCallSummary({ callId: this.call.call_id })
      }
    },
    handleCRMItemUpdated (answers, updatedItem) {
      this.itemsWithAnswersToSave.forEach(crmItemWithAnswers => {
        if (crmItemWithAnswers.item_id === updatedItem.id) {
          answers.forEach(selectedAnswer => {
            const index = crmItemWithAnswers.answersToSave.findIndex(ans => ans.question_id === selectedAnswer.question_id)
            if (index > -1) {
              crmItemWithAnswers.answersToSave.splice(index, 1)
            }
          })
          answers.forEach(selectedAnswer => {
            crmItemWithAnswers.answersToSave.push(selectedAnswer)
          })
        }
      })
    },
    getValueType (originalType) {
      const typeMapping = {
        date: "date",
        datetime: "datetime",
        tel: "number",
        integer: "integer",
        number: "number",
        booleancheckbox: "boolean",
        float: "float"
      }
      return typeMapping[originalType] || "text"
    },
    async handleModalOpen () {
      // return if no transcript or CRM Fields are available.
      if (!this.transcript || !this.crmFields) {
        this.detectedFields = null
        return
      }
      // return if data is already loaded.
      if (this.loadingCompleted) return

      // retrieve the CRM field values from API and set them as answers
      await this.detectCRMFieldsFromTranscript().then(data => {
        this.loadingCompleted = true
        this.setSuggestedAnswersOnCRMItems(data.results)
      })
    },
    setSuggestedAnswersOnCRMItems (results) {
      this.crmItems = this.crmItems.map(crmItem => {
        const suggestedAnswers = crmItem.linked_field.field_definition.flatMap(fieldDef => {
          const detectedAnswer = results.find(result => result.question_id === fieldDef.id)
          if (detectedAnswer && detectedAnswer.value) {
            if (this.shouldUseDropdown(fieldDef.fieldType)) {
              return this.getMatchingValue(fieldDef, detectedAnswer.value, fieldDef.options) || []
            } else {
              return { ...detectedAnswer, text: detectedAnswer.value }
            }
          }
          return []
        }).filter(ans => ans && ans.text)

        crmItem.suggestedAnswers = suggestedAnswers

        this.itemsWithAnswersToSave.push({
          item_id: crmItem.id,
          answersToSave: suggestedAnswers
        })

        return crmItem
      })
    },
    getMatchingValue (fieldDefinition, value, fieldOptions) {
      const getMatch = (val, options) => options.find(option => val.match(new RegExp(option.label, "gi")))

      if (Array.isArray(value) && value.length > 1) {
        const matchedValues = value.map(item => getMatch(item, fieldOptions)).filter(Boolean)
        return matchedValues.length
          ? matchedValues.map(match => ({
            question_id: fieldDefinition.id,
            text: match
          }))
          : null
      } else {
        const match = getMatch(value, fieldOptions)
        return match ? { question_id: fieldDefinition.id, text: match } : null
      }
    },
    async detectCRMFieldsFromTranscript () {
      try {
        this.loadingCompleted = false // reset loadingCompleted
        this.loading = true
        const url = "/api/calls/auto_detect_crm_fields/"
        const payload = {
          transcript: this.transcript,
          crm_fields: this.crmFields
        }
        const { data } = await this.axios.post(url, payload)
        this.loading = false
        return data
      } catch (e) {
        this.loading = false
        console.error(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">

.crm-auto-detect-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: $orange12;
  svg {
    path {
      fill: $orange;
      &.wand {
        stroke: $orange;
      }
    }
  }

}

</style>
