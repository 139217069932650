<template>
  <div
    v-if="!!isMeetingEnded"
    :class="['call-container ', {'p-3': !isOpenFromCallViewSummary}]"
  >
    <div v-if="showVideoPlayer"
         class="mb-2" >
      <bao-video-player
        ref="videoPlayer"
        v-if="!!videoUrl"
        @video-time-changed="currentTime = $event"
      />
      <div v-else>
        <ProcessSpinner
          :processingMessage="staticText.meetingVideoProcessingMessage"/>
      </div>
    </div>

    <b-tabs
      v-model="activeTabIndex"
      active-nav-item-class="border-left-0 border-top-0 border-right-0"
      nav-class="no-border d-flex flex-wrap"
    >
      <template
        v-if="showSaveToCrmToggleComputed"
        slot="tabs-end"
      >
        <div
          class="save-to-crm">
          <bao-toggle-button
            id="save-to-crm-toggle"
            :isActive="shouldSaveAISummaryToCrm"
            :label="staticText.saveToCRMLabel"
            :isDisabled="isCallSavedToCrm"
            button-class="minBtn font-14"
            @toggle="toggleSaveAISummaryToCrm"
          ></bao-toggle-button>
        </div>
      </template>
      <!-- transcript summary tab   -->
      <b-tab
        :title="staticText.summaryTabTitle"
        :class="['tab-content border-radius-tl-0', { 'border-tr-0' : showSaveToCrmToggleComputed }]"
      >
        <template slot="title">
          <div class="d-flex align-items-center">
            <div class="mr-1">{{staticText.summaryTabTitle}}</div>
            <bao-copy-button
              v-if="meetingSummaryAvailable"
              ref="baoCopySummaryButton"
              :shouldInitiateCopyProcess="true"
              @initiate-copy-process="copyTranscriptSummary"
            />
          </div>
        </template>
        <div v-if="meetingSummaryAvailable"
             class="tab-content__container tab-content__summary"
             ref="meeting-summary"
        >
          <!-- This & BaoAudio Summary section, should be extracted into a seperate component when Bao-Audio-Resturcture #2 is done-->
          <div class="d-flex summary-block">
            <div v-for="summaryType in summaryVariants"
                 :key="summaryType.key"
            >
              <button class="mr-2 summary-button"
                      :class="{'active-summary': summaryType.key==currentSummaryTab}"
                      @click="getSummaryData(summaryType.key)">
                {{summaryType.title}}</button>
            </div>
          </div>
          <div class="mt-4 mb-2 summary-container"
               ref="transcriptSummary">
            <div
              v-if="currentSummaryTab==SUMMARY_TYPES.BULLETPOINTS"
            >
              <ul class="bullet-point-summary">
                <li v-for="(line, index) in meetingSummary[currentSummaryTab]" :key="index">{{line}}</li>
              </ul>
            </div>
            <div v-else-if="currentSummaryTab == SUMMARY_TYPES.TOPIC_BLOCKS">
              <div v-for="(summary, summaryIndex) in meetingSummary[currentSummaryTab]" :key="'summary-' + summaryIndex">
                <h6>{{ summary.title }} ({{ summary.time_range }})</h6>
                <ul>
                  <li v-for="(detail, index) in summary.details" :key="'detail-' + summaryIndex + '-' + index">
                    {{ detail }}
                  </li>
                </ul>
              </div>
            </div>
            <div v-else-if="currentSummaryTab == SUMMARY_TYPES.ACTION_ITEMS">
              <ul>
                <li v-for="(summary, summaryIndex) in meetingSummary[currentSummaryTab]" :key="'summary-' + summaryIndex">
                  <h6>{{ summary.title }}</h6>
                  <p>{{ summary.details }}</p>
                </li>
              </ul>
            </div>
            <div v-else-if="currentSummaryTab == SUMMARY_TYPES.EMAIL">
              <h6>{{ meetingSummary[currentSummaryTab].subject }}</h6>
              <p>{{ meetingSummary[currentSummaryTab].body }}</p>
            </div>
            <div v-else>
              {{ meetingSummary[currentSummaryTab] || staticText.noSummaryAvailableText }}
            </div>
            <div v-if="meetingSummaryAvailable" class="summary-disclaimer mt-2">
              {{ staticText.summaryDisclaimer }}
            </div>
          </div>
        </div>
        <div v-else-if="isMeetingDataProcessingDone">
          {{ staticText.noSummaryAvailableText }}
        </div>
        <div v-else>
          <ProcessSpinner
            :processingMessage="staticText.meetingSummaryProcessingMessage"/>
        </div>
      </b-tab>
      <!-- transcript  -->
      <b-tab
        v-if="!isOpenFromCallViewSummary"
        :title="staticText.transcriptTabTitle"
        class="tab-content"
      >
        <template slot="title">
          <div class="d-flex align-items-center">
            <div class="mr-1">{{staticText.transcriptTabTitle}}</div>
            <bao-copy-button
              v-if="meetingTranscript.length"
              ref="baoCopyTranscriptButton"
              :shouldInitiateCopyProcess="true"
              @initiate-copy-process="copyTranscript"
            />
          </div>
        </template>
        <div v-if="!!meetingTranscript.length"
             class="tab-content__transcript"
             ref="meeting-transcript"
        >
          <bao-video-transcript
            :meetingTranscript="meetingTranscript"
            :currentTime="currentTime"
            @update-time="seekVideo"
          />
        </div>
        <div v-else-if="isMeetingDataProcessingDone">
          {{ staticText.noTranscriptAvailableText }}
        </div>
        <div v-else>
          <ProcessSpinner
            :processingMessage="staticText.meetingTranscriptProcessingMessage"/>
        </div>
      </b-tab>
    </b-tabs>
  </div>
  <div v-else>
    <ProcessSpinner
      :processingMessage="staticText.meetingInProgressMessage"/>
  </div>
</template>

<script>
import BaoVideoPlayer from "@/apps/call_history/CallFeedbackComponents/BaoVideoPlayer"
import BaoVideoTranscript from "@/apps/call_history/CallFeedbackComponents/BaoVideoTranscript"
import BaoToggleButton from "@/apps/base/BaoToggleButton"
import BaoCopyButton from "@/apps/base/BaoCopyButton"
import ProcessSpinner from "@/apps/base/ProcessSpinner"
import { SUMMARY_TYPES } from "@/apps/call_history/CallFeedbackComponents/constants"
import { setCookie, getCookie } from "@/utils/utils"

import { mapGetters, mapActions } from "vuex"
export default {
  name: "BaoVideo",
  components: {
    BaoToggleButton,
    BaoVideoPlayer,
    BaoCopyButton,
    BaoVideoTranscript,
    ProcessSpinner
  },
  data () {
    return {
      activeTabIndex: 0,
      fetchAudio: false,
      fetchTranscripts: false,
      fetchSummary: false,
      isMeetingDataProcessingDone: false,
      currentTime: 0,
      seekTime: 0,
      currentSummaryTab: "default",
      staticTextDefault: {
        transcriptTabTitle: "AI Transcript",
        meetingVideoProcessingMessage: "The video for this meeting is currently being processed. The data should usually be available in a few seconds, but it can take a while for long calls. Please wait, the page will be updated automatically.",
        meetingSummaryProcessingMessage: "The summary for this meeting is currently being processed. The data should usually be available in a few seconds, but it can take a while for long calls. Please wait, the page will be updated automatically.",
        meetingTranscriptProcessingMessage: "The transcript for this meeting is currently being processed. The data should usually be available in a few seconds, but it can take a while for long calls. Please wait, the page will be updated automatically.",
        meetingInProgressMessage: "The connected video meeting is still in progress. The data will be available once the meeting ends. Please wait, the page will be updated automatically.",
        summaryTabTitle: "AI Summary",
        noSummaryAvailableText: "Sadly no summary available for this call.",
        noTranscriptAvailableText: "Sadly no transcript available for this call.",
        saveToCRMLabel: "Save to CRM",
        defaultSummaryLabel: "Default",
        shortSummaryLabel: "Short",
        longSummaryLabel: "Long",
        bulletpointsSummaryLabel: "Bulletpoints",
        topicBlocksSummaryLabel: "Topic Blocks",
        actionItemsSummaryLabel: "Action Items",
        emailSummaryLabel: "Email",
        summaryDisclaimer: "This summary was computer generated and might contain errors."
      },
      SUMMARY_TYPES
    }
  },
  props: {
    isOpenFromCallViewSummary: {
      default: false
    },
    callId: {
      required: true
    },
    showVideoPlayer: {
      required: true
    },
    showSaveToCrmToggle: {
      type: Boolean
    },
    shouldSaveAISummaryToCrm: {
      type: Boolean,
      default: true
    },
    isCallSavedToCrm: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      isMeetingEnded: "callVideoStore/isMeetingEnded",
      getMeetingSummary: "callVideoStore/getMeetingSummary",
      meetingTranscript: "callVideoStore/getMeetingTranscript",
      videoUrl: "callVideoStore/getVideoUrl"
    }),
    meetingSummary () {
      return this.getMeetingSummary ? this.getMeetingSummary : {}
    },
    meetingSummaryAvailable () {
      return Object.keys(this.meetingSummary).length !== 0
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    showSaveToCrmToggleComputed () {
      return this.showSaveToCrmToggle && this.meetingSummary && this.activeTabIndex === 0 // Index 0 is for summary tab
    },
    summaryVariants () {
      return [
        {
          title: this.staticText.defaultSummaryLabel,
          key: "default"
        },
        {
          title: this.staticText.shortSummaryLabel,
          key: "short"
        },
        {
          title: this.staticText.longSummaryLabel,
          key: "long"
        },
        {
          title: this.staticText.bulletpointsSummaryLabel,
          key: "bulletpoints"
        },
        {
          title: this.staticText.topicBlocksSummaryLabel,
          key: "topic_blocks"
        },
        {
          title: this.staticText.actionItemsSummaryLabel,
          key: "action_items"
        }
        // {
        //   title: this.staticText.emailSummaryLabel,
        //   key: "email"
        // }
      ]
    }
  },
  methods: {
    ...mapActions({
      fetchCallMeetingData: "callVideoStore/fetchCallMeetingData",
      setCurrentSummaryText: "callSummaryStore/setCurrentSummaryText"
    }),
    setCurrentSummaryTabFromCookie () {
      const favoriteSummaryFormat = getCookie("favoriteSummaryFormat")
      this.currentSummaryTab = favoriteSummaryFormat || "default"
    },
    async setCurrentSummary () {
      await this.sleep(100)
      const transcriptSummary = this.$refs.transcriptSummary
      const summaryText = transcriptSummary ? transcriptSummary.innerText : ""
      this.setCurrentSummaryText({ summaryText })
    },
    toggleSaveAISummaryToCrm () {
      this.$emit("toggleSaveAISummaryToCrm")
    },
    copyTranscript () {
      const textToCopy = this.$refs["meeting-transcript"].innerText
      this.$refs.baoCopyTranscriptButton.copyText(textToCopy)
    },
    async copyTranscriptSummary () {
      const transcriptSummary = this.$refs.transcriptSummary

      if (!transcriptSummary) {
        console.error("Transcript summary element not found")
        return
      }

      try {
        const range = document.createRange()
        range.selectNodeContents(transcriptSummary)
        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)

        const fragment = range.cloneContents()
        const div = document.createElement("div")
        div.appendChild(fragment)

        const { ClipboardItem } = window
        await navigator.clipboard.write([
          new ClipboardItem({
            "text/html": new Blob([div.innerHTML], { type: "text/html" }),
            "text/plain": new Blob([transcriptSummary.innerText], { type: "text/plain" })
          })
        ])
        this.$refs.baoCopySummaryButton.flashCopiedMessage() // Display the "copied" popup
      } catch (err) {
        console.error("Failed to copy content with format", err)
        const textToCopy = this.$refs.transcriptSummary.innerText
        this.$refs.baoCopySummaryButton.copyText(textToCopy)
      } finally {
        const selection = window.getSelection()
        selection.removeAllRanges()
      }
    },
    async updateMeetingData () {
      await this.fetchCallMeetingData({ callId: this.callId })
    },
    checkAndUpdateMeetingData () {
      if (!this.isMeetingEnded || Object.keys(this.meetingSummary).length === 0) {
        this.updateMeetingData()
      } else {
        this.isMeetingDataProcessingDone = true
        clearInterval(this.intervalForRefreshingMeetingData)
        this.setCurrentSummary()
      }
    },
    seekVideo (timeInSeconds) {
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.seekTo(timeInSeconds)
      }
    },
    getSummaryData (summaryType) {
      // Set the "favorite" summary format to clicked tab
      setCookie("favoriteSummaryFormat", summaryType)
      this.currentSummaryTab = summaryType
      this.setCurrentSummary()
    }
  },
  mounted () {
    this.setCurrentSummaryTabFromCookie()
    // reset the current summary if set already
    this.setCurrentSummaryText({ summaryText: "" })
    this.checkAndUpdateMeetingData()
    this.intervalForRefreshingMeetingData = setInterval(this.checkAndUpdateMeetingData, 5000)
  },
  beforeDestroy () {
    clearInterval(this.intervalForRefreshingMeetingData)
  }
}
</script>

<style scoped lang="scss">

.tab-content {
  background: #FFF;
  border-radius: 16px;
  padding: 16px 8px 16px 16px;

  .border-radius-tl-0 {
    border-radius: 0 16px 16px;
    @include media-breakpoint-down(xs) {
      &.border-tr-0 {
        border-radius: 0 0 16px 16px;
      }
    }
  }
}

.call-container  {
  :deep(.nav-link.active) {
    border-radius: 16px 16px 0 0;
  }
}

.save-to-crm {
  display: flex;
  margin-left: auto;
  @include media-breakpoint-down(xs) {
    padding: 10px 0 10px 16px;
    width: 100%;
    background-color: white;
    border-radius: 0 16px 0 0;
  }
}

.summary-block{
  overflow-x: auto;
}

.summary-button {
  padding: 9px 10px;
  border-radius: 12px;
  line-height:14px;
  justify-content: center;
  font-size: 12px;
  align-items: center;
  background-color: $slate06;
  outline: none;
  border: none;
  color: $slate80;
  height: fit-content;
  }

.active-summary{
  background-color: #FF56321F !important;
  color: #FF5632 !important;
}

.summary-type {
  color: $slate40;
  font-size: 12px;
}

.regenerate-btn {
  //over write some styles of regenerate button
  padding: 8px 10px;
  line-height: 14px;
  svg {
    height: 16px;
    width: 16px;
  }
}

.summary-container {
  color: #00000080;
  overflow-y: scroll;
  max-height: 300px;
}

.bullet-point-summary{
  padding-left: 16px;
  margin-bottom: 0;
}

.summary-disclaimer{
  color: $slate40;
  font-size: 12px;
}
</style>
