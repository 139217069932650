<template>
  <div class="call_flow_items py-2 px-3">
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <!--   Filter dropdown     -->
        <div class="call_flow_filter mb-2">
          <span>{{ staticText.filterByLabel }}</span>
          <div class="filter-dropdown">
            <vue-multiselect
              v-model="activeFilter"
              label="label"
              track-by="value"
              :searchable="false"
              :options="callItemFilterOptions"
              :placeholder="staticText.selectOption"
              :show-labels="false"
              :allow-empty="false"
              @close="separateCallItems"
            ></vue-multiselect>
          </div>
        </div>

        <!-- Refresh view button-->
        <b-btn id="refresh-button-2024717911" class="refresh-button"
               :class="{'icon-only': isApplicationInIFrame}"
               :disabled="!itemsUpdated || itemUpdating"
               @click="separateCallItems"
        >
          <img src="@/assets/svgs/refresh-icon.svg" svg-inline>
          <span v-if="!isApplicationInIFrame">
            {{ staticText.refreshLabel }}
          </span>
        </b-btn>
        <b-tooltip v-if="itemsUpdated"
                   target="refresh-button-2024717911"
                   triggers="hover"
        >
          {{ staticText.refreshBtnTooltipText }}
        </b-tooltip>
      </div>
      <div v-if="currentFilteredItems.length">
        <div
          v-for="callFlowItem in currentFilteredItems"
          :id="`${callFlowItem.playbookIndex}-${callFlowItem.id}`"
          :key="`${callFlowItem.playbookIndex}-${callFlowItem.id}`"
          class="call_flow_item"
        >
          <call-flow-call-item
            :item-position="callFlowItem.playbookIndex+1"
            :call-flow-item="callFlowItem"
            :isCallSavedToCrm="isCallSavedToCrm"
            @item-updated="handleItemUpdated"
          />
        </div>
      </div>
      <call-flow-items-empty-state
        v-else
        :active-filter="activeFilter.value"
        @set-unanswered-filter="setUnansweredFilter"
      />
    </div>
  </div>
</template>

<script>
import CallFlowCallItem from "./CallFlowCallItem.vue"
import CallFlowItemsEmptyState from "./CallFlowItemsEmptyState.vue"
import { ANSWERED_FILTER, UNANSWERED_FILTER, CRM_ITEMS_FILTER, ALL_ITEMS_FILTER } from "./constants.js"
import CallItemMixin from "@/apps/call/CallItemMixin"
import { isUnansweredItem } from "../index.js"
import { mapActions, mapGetters } from "vuex"
import { utils } from "@/apps/call"
import VueMultiselect from "vue-multiselect"

export default {
  name: "CallFlowItems",
  mixins: [CallItemMixin],
  components: {
    CallFlowCallItem,
    CallFlowItemsEmptyState,
    VueMultiselect
  },
  data () {
    return {
      answeredItems: [],
      unAnsweredItems: [],
      crmItems: [],
      allItems: [],
      activeFilter: {},
      itemsUpdated: false,
      itemUpdating: false,
      staticTextDefault: {
        answeredLabel: "Answered",
        unansweredLabel: "Unanswered",
        crmItemsLabel: "CRM-Items",
        allItemsLabel: "All Items",
        filterByLabel: "Filter by:",
        selectOption: "Select option",
        refreshLabel: "Refresh",
        refreshBtnTooltipText: "Refreshes the filtered view and items"
      }
    }
  },
  props: {
    isCallSavedToCrm: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted () {
    this.separateCallItems()
    this.setAnsweredFilter()
  },
  computed: {
    ...mapGetters({
      playbookItems: "callSummaryStore/getPlaybookItems",
      callDetails: "callSummaryStore/getCallDetails"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    isCrmItemPresent () {
      return this.crmItems.length
    },
    callItemFilterOptions () {
      const defaultOptions = [
        {
          value: ANSWERED_FILTER,
          label: this.staticText.answeredLabel
        },
        {
          value: UNANSWERED_FILTER,
          label: this.staticText.unansweredLabel
        },
        {
          value: ALL_ITEMS_FILTER,
          label: this.staticText.allItemsLabel
        }
      ]
      const crmOption = {
        value: CRM_ITEMS_FILTER,
        label: this.staticText.crmItemsLabel
      }
      if (this.isCrmItemPresent) defaultOptions.splice(2, 0, crmOption)
      return defaultOptions
    },
    currentFilteredItems () {
      switch (this.activeFilter.value) {
        case ANSWERED_FILTER:
          return this.answeredItems
        case UNANSWERED_FILTER:
          return this.unAnsweredItems
        case CRM_ITEMS_FILTER:
          return this.crmItems
        case ALL_ITEMS_FILTER:
          return this.allItems
        default:
          return this.answeredItems
      }
    }
  },
  methods: {
    ...mapActions({
      handleCallItemChanged: "callSummaryStore/handleCallItemChanged"
    }),
    async handleItemUpdated (callFlowItem) {
      this.itemUpdating = true
      await this.handleCallItemChanged({ item: callFlowItem, callId: this.callDetails.id }).then(() => {
        this.itemUpdating = false
        this.itemsUpdated = true
      })
    },
    separateCallItems () {
      this.answeredItems = []
      this.unAnsweredItems = []
      if (this.playbookItems && this.playbookItems.length) {
        const [unAnsweredItems, answeredItems, crmItems, allItems] = this.filterItems(this.playbookItems)
        this.answeredItems = answeredItems
        this.unAnsweredItems = unAnsweredItems
        this.crmItems = crmItems
        this.allItems = allItems
      }
      this.itemsUpdated = false
    },
    checkLoadedADCs (playbookItem, answeredItems, unAnsweredItems, crmItems, allItems) {
      if (this.loadedADCs(playbookItem)) {
        for (const adc of this.loadedADCs(playbookItem)) {
          const [filterUnAnsweredItems, filterAnsweredItems, filterCrmItems, filteredAllItems] = this.filterItems(adc, true)
          if (filterUnAnsweredItems.length) {
            unAnsweredItems.push({
              ...adc,
              isAdditionalItem: true,
              children: filterUnAnsweredItems
            })
          }
          if (filterAnsweredItems.length) {
            answeredItems.push({
              ...adc,
              isAdditionalItem: true,
              children: filterAnsweredItems
            })
          }
          if (filterCrmItems.length) crmItems.push({ ...adc, isAdditionalItem: true, children: filterCrmItems })
          if (filteredAllItems.length) allItems.push({ ...adc, isAdditionalItem: true, children: filteredAllItems })
        }
      }
    },
    filterItems (adc, isAdditionalData = false) {
      const unAnsweredItems = []
      const answeredItems = []
      const crmItems = []
      const allItems = []
      const playbookList = isAdditionalData ? this.getChildren(adc).entries() : adc.entries()
      for (const [index, playbookItem] of playbookList) {
        const transformedPlaybook = this.transformItem(playbookItem, index, isAdditionalData ? adc : null)
        if (this.isUnansweredItem(playbookItem)) unAnsweredItems.push(transformedPlaybook)
        else answeredItems.push(transformedPlaybook)

        if (this.isItemType(playbookItem, "crmlink") && playbookItem.linked_field) crmItems.push(transformedPlaybook)

        allItems.push(transformedPlaybook)

        this.checkLoadedADCs(playbookItem, answeredItems, unAnsweredItems, crmItems, allItems)
      }
      return [unAnsweredItems, answeredItems, crmItems, allItems]
    },
    transformItem (item, index, additionalData = null) {
      item.playbookId = this.callDetails.talkscript.id
      item.uniqueId = utils.getUniqueId()
      item.playbookIndex = index
      if (item.call_item) {
        item.callItem = item.call_item
        item.notes = item.call_item.note
        item.duration = item.call_item.duration
      }
      if (additionalData && additionalData.call) item.additional_data = additionalData.id
      return item
    },
    isUnansweredItem (playbookItem) {
      const callItem = playbookItem.call_item
      return this.hasAnswerOptions(playbookItem) && (!callItem || isUnansweredItem(callItem))
    },
    setAnsweredFilter () {
      this.activeFilter = this.callItemFilterOptions.filter(filter => filter.value === ANSWERED_FILTER)[0]
    },
    setUnansweredFilter () {
      this.activeFilter = this.callItemFilterOptions.filter(filter => filter.value === UNANSWERED_FILTER)[0]
    }
  }
}
</script>

<style lang="scss" scoped>

.call_flow_item {
  border-bottom: 1px solid rgba(42, 45, 82, 0.1);
  margin-bottom: 8px;
}

.call_flow_filter {
  display: flex;
  align-items: center;
  span {
    color: $slate40;
  }
  .filter-dropdown {
    cursor: pointer;
    margin-left: 16px;
    width: 200px;
    :deep(.multiselect) {
      min-height: unset;
      .multiselect__tags {
        padding: 12px 40px 8px 8px;
        min-height: unset !important;
      }
      .multiselect__single,
      .multiselect__input {
        padding: 0 0 0 8px !important;
        margin: 0 !important;
      }
      .multiselect__select {
        top: 11px !important;
      }
    }
  }
}

button.refresh-button {
  padding: 4px 6px;
  display: flex;
  align-items: center;
  font-size: 12px;
  border-radius: 16px;
  img {
    height: 12px;
    margin-right: 2px;
  }
  &.icon-only {
    padding: 6px;
    border-radius: 50%;
    img {
      margin-right: unset;
    }
  }
}

</style>
