<template>
  <div>
    <div v-if="transformedTranscript.length > 0">
      <div ref="transcripts">
        <div class="d-flex mb-2 align-items-center justify-content-between">
          <div class="search-bar">
            <b-form-input
              v-model="query"
              :placeholder="staticText.searchPlaceholder"
              class="shadow-sm"
              @input="searchQuery"
            ></b-form-input>
            <span class="search-icon">
              <img src="../../../../public/img/icons/search-icon.svg"/>
            </span>
            <span class="search-label">
              {{ staticText.searchLabel }}
            </span>
            <span
              class="cancel-icon"
              v-show="!!query"
              @click="resetSearch">
              <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="a" fill="#fff"><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z"/></mask><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z" fill="#000" fill-opacity=".5"/><path d="M7 .705l.707.707.707-.707-.707-.707L7 .705zM6.295 0l.707-.707-.707-.707-.707.707.707.707zM3.5 2.795l-.707.707.707.707.707-.707-.707-.707zM.705 0l.707-.707-.707-.707-.707.707L.705 0zM0 .705l-.707-.707-.707.707.707.707L0 .705zM2.795 3.5l.707.707.707-.707-.707-.707-.707.707zM0 6.295l-.707-.707-.707.707.707.707L0 6.295zM.705 7l-.707.707.707.707.707-.707L.705 7zM3.5 4.205l.707-.707L3.5 2.79l-.707.707.707.707zM6.295 7l-.707.707.707.707.707-.707L6.295 7zM7 6.295l.707.707.707-.707-.707-.707L7 6.295zM4.205 3.5l-.707-.707-.707.707.707.707.707-.707zM7.707-.002l-.705-.705L5.588.707l.705.705L7.707-.002zm-2.12-.705L2.794 2.088l1.414 1.414L7.002.707 5.588-.707zm-1.38 2.795L1.412-.707-.002.707l2.795 2.795 1.414-1.414zM-.003-.707l-.704.705L.707 1.412l.705-.705L-.002-.707zm-.704 2.12l2.795 2.794 1.414-1.414L.707-.002-.707 1.412zm2.795 1.38L-.707 5.588.707 7.002l2.795-2.795-1.414-1.414zm-2.795 4.21l.705.704 1.414-1.414-.705-.705-1.414 1.414zm2.12.704l2.794-2.795-1.414-1.414-2.795 2.795 1.414 1.414zm1.38-2.795l2.795 2.795 1.414-1.414-2.795-2.795-1.414 1.414zm4.21 2.795l.704-.705-1.414-1.414-.705.705 1.414 1.414zm.704-2.12L4.912 2.794 3.498 4.207l2.795 2.795 1.414-1.414zm-2.795-1.38l2.795-2.795L6.293-.002 3.498 2.793l1.414 1.414z" fill="#2A2D52" fill-opacity=".01" mask="url(#a)"/></svg>
            </span>
          </div>
          <button
            class="regenerate-btn ml-2 h-100"
            @click="openRegenerateTranscriptModal"
          >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-1'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.048 13.75a.4.4 0 00.316-.645L4.51 9.409a.4.4 0 00-.633 0l-2.874 3.697a.4.4 0 00.316.645h1.225c.22 0 .4.18.4.4v.787c0 1.965 1.643 3.563 3.664 3.563h7.329c2.02 0 3.664-1.598 3.664-3.563v-.787a.4.4 0 00-.4-.4h-1.643a.4.4 0 00-.4.4v.787c0 .655-.548 1.188-1.221 1.188H6.607c-.673 0-1.221-.533-1.221-1.188v-.787c0-.22.179-.4.4-.4h1.262zM12.952 6.15a.4.4 0 00-.316.645l2.856 3.697a.4.4 0 00.632 0l2.874-3.696a.4.4 0 00-.316-.646h-1.225a.4.4 0 01-.4-.4v-.787c0-1.965-1.643-3.563-3.664-3.563H6.064C4.044 1.4 2.4 2.998 2.4 4.963v.787c0 .221.18.4.4.4h1.643a.4.4 0 00.4-.4v-.787c0-.654.548-1.188 1.221-1.188h7.329c.673 0 1.221.534 1.221 1.188v.787a.4.4 0 01-.4.4h-1.262z" fill="#555775"/></svg>
            {{ staticText.regenerateLabel }}
          </button>
        </div>
        <hr class="my-auto">
        <div class="transcripts-container" ref="transcriptsText">
          <div v-for="(transcript, index) in transformedTranscript"
               :key="index"
          >
            <div :class="['transcript-block', {'active': isHighlighted(transcript)}]">
              <div>{{ getDisplayTimeStamp(transcript.start) }}</div>
              <!-- <div class="transcripts__container__block__speaker text-truncate">{{transcript.speaker}}</div>-->
              <div class="transcript-block__text"
                   @click="emitTextClick(transcript)"
              >
                {{ transcript.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Case where there is no transcription but audio is there, can happen if languages are mismatched -->
    <!-- We should allow user to use `regen` button here -->
    <div v-else-if="callAudioData.duration && callAudioData.duration > 0">
      <div class="d-flex flex-row justify-content-end">
        <button
          class="regenerate-btn ml-2 h-100"
          @click="openRegenerateTranscriptModal"
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-1'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.048 13.75a.4.4 0 00.316-.645L4.51 9.409a.4.4 0 00-.633 0l-2.874 3.697a.4.4 0 00.316.645h1.225c.22 0 .4.18.4.4v.787c0 1.965 1.643 3.563 3.664 3.563h7.329c2.02 0 3.664-1.598 3.664-3.563v-.787a.4.4 0 00-.4-.4h-1.643a.4.4 0 00-.4.4v.787c0 .655-.548 1.188-1.221 1.188H6.607c-.673 0-1.221-.533-1.221-1.188v-.787c0-.22.179-.4.4-.4h1.262zM12.952 6.15a.4.4 0 00-.316.645l2.856 3.697a.4.4 0 00.632 0l2.874-3.696a.4.4 0 00-.316-.646h-1.225a.4.4 0 01-.4-.4v-.787c0-1.965-1.643-3.563-3.664-3.563H6.064C4.044 1.4 2.4 2.998 2.4 4.963v.787c0 .221.18.4.4.4h1.643a.4.4 0 00.4-.4v-.787c0-.654.548-1.188 1.221-1.188h7.329c.673 0 1.221.534 1.221 1.188v.787a.4.4 0 01-.4.4h-1.262z" fill="#555775"/></svg>
          {{ staticText.regenerateLabel }}
        </button>
      </div>
      <div class="mt-2 mb-2 no-transcript-text">{{ staticText.audioInputLanguageMismatch }}</div>
    </div>
    <div v-else class="mt-4 mb-2 no-transcript-text">{{ staticText.noTranscriptsMessage }}</div>

    <!-- Regenerate Transcript Modal -->
    <regenerate-transcript-and-summary-modal
      ref="regenerateTranscriptModal"
      :call-id="callId"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Mark from "mark.js"
import RegenerateTranscriptAndSummaryModal from "./RegenerateTranscriptAndSummaryModal.vue"

export default {
  name: "BaoAudioTranscript",
  components: {
    RegenerateTranscriptAndSummaryModal
  },
  data () {
    return {
      query: null,
      staticTextDefault: {
        noTranscriptsMessage: "Sadly no transcript available for this call.",
        audioInputLanguageMismatch: "Unfortunately, no meaningful transcript could be generated automatically (the language of the playbook or the default language for audio may not match the actual language). Please regenerate the transcript using the button on the right.",
        searchPlaceholder: "Type to search.",
        searchLabel: "Search",
        regenerateLabel: "Regenerate"
      }
    }
  },
  props: {
    callId: {
      required: true
    },
    currentTime: { // milliseconds
      type: Number,
      required: false,
      default: () => 0
    },
    getDisplayTimeStamp: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      callAudioData: "callAudioStore/getCall"
    }),
    transcriptBlocks () {
      return [...this.$refs.transcriptsText.getElementsByClassName("transcript-block")]
    },
    transformedTranscript () {
      const responseData = this.callAudioData.transcription ? this.callAudioData.transcription.response_data : []
      return responseData || []
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    resetSearch () {
      this.query = ""
      this.resetMarked()
    },
    searchQuery () {
      if (this.query === "") {
        this.resetMarked()
      }
      const instance = new Mark(this.$refs.transcriptsText)
      instance.unmark()
      instance.mark(this.query, {
        element: "span",
        className: "text-secondary",
        each: (node) => this.filterMarked(node),
        noMatch: () => this.setNoMatch()
      })
    },
    filterMarked (node) {
      const searchText = node.innerText.toLowerCase()

      this.transcriptBlocks.forEach((item) => {
        const itemText = item.innerText.toLowerCase()

        if (itemText.includes(searchText)) {
          if (item.classList.contains("d-none")) {
            item.classList.remove("d-none")
          }
        } else {
          if (!item.classList.contains("d-none")) {
            item.classList.add("d-none")
          }
        }
      })
    },
    resetMarked () {
      this.transcriptBlocks.forEach((item) => {
        if (item.classList.contains("d-none")) {
          item.classList.remove("d-none")
        }
      })
      const instance = new Mark(this.$refs.transcriptsText)
      instance.unmark()
    },
    setNoMatch () {
      this.transcriptBlocks.forEach((item) => {
        item.classList.add("d-none")
      })
    },
    getTranscriptsToCopy () {
      return this.$refs.transcriptsText.innerText
    },
    isHighlighted (transcript) {
      if (this.currentTime > 0) {
        return this.currentTime > transcript.start && (this.currentTime < transcript.end || transcript.end === null)
      }
      return false
    },
    emitTextClick (transcript) {
      this.$emit("text-clicked", transcript.start)
    },
    openRegenerateTranscriptModal () {
      this.$refs.regenerateTranscriptModal.openModal()
    }
  }
}

</script>

<style scoped lang="scss">

.transcripts-container {
  max-height: 300px;
  overflow-y: scroll;
}

.no-transcript-text {
  color: #00000080;
}

.transcript-block {
  display: grid;
  grid-template-columns: 1fr 12fr;
  padding: 8px;
  border-radius: 8px;

  &.active {
    background: #F0E7DF;
  }

  &__speaker {
    margin-inline: 16px;
    min-width: 100px;
    max-width: 100px;
  }

  &__text {
    cursor: pointer;
  }
}

.search-bar {
  position: relative;

  .search-label {
    top: 2px;
    color: $slate40;
    position: absolute;
    left: 16px;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    font-size: 11px;
  }

  input:not(:placeholder-shown) ~ .search-label {
    bottom: 100%;
    opacity: 1;
    visibility: visible;
  }

  input {
    font-size: 12px;
    padding: 4px 32px;
    height: 44px;
    background-color: $white40;
    box-shadow: 14px 12px 40px rgba(199, 199, 199, 0.12);
    border: none;
    border-radius: 12px;
  }

  .search-icon {
    top: 0;
    height: 100%;
    color: $slate40;
    position: absolute;
    display: flex;
    align-items: center;
    left: 16px;
    img {
      height: 14px;
      width: 14px;
    }
  }

  .cancel-icon {
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 16px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s ease-in;
    svg {
      height: 7px;
      width: 7px;
    }
  }
}
</style>
