<template>
  <div
    v-if="!!videoUrl"
    class="video-container"
  >
    <video
      :src="videoUrl"
      class="video-player"
      controls
      disablepictureinpicture
      controlsList="nodownload noplaybackrate"
      id="meeting-video"
      @play="startUpdatingTime"
      @pause="stopUpdatingTime"
      @seeked="handleTimeUpdate"
      ref="meetingVideo"
    ></video>
    <video-controls></video-controls>
    <div class="top-right-style">
      <meeting-participants />

      <div class="share-video ml-3" @click="shareBaoVideo">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:id="'share-bao-video-btn'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.501 10.284l-.567.563a1.27 1.27 0 01-1.781 0 1.234 1.234 0 010-1.753l2.086-2.068c.432-.43 1.245-1.06 1.838-.473a.694.694 0 10.978-.984c-1.007-1-2.496-.815-3.793.472l-2.086 2.07A2.603 2.603 0 001.4 9.97a2.604 2.604 0 00.776 1.86 2.645 2.645 0 001.868.768c.677 0 1.354-.256 1.868-.768l.568-.563a.692.692 0 00-.487-1.185.696.696 0 00-.492.2zm6.323-8.04c-1.083-1.073-2.597-1.131-3.598-.137l-.706.701a.693.693 0 00.977.985l.707-.701c.518-.515 1.198-.302 1.643.138a1.23 1.23 0 010 1.752L8.62 7.19c-1.018 1.008-1.496.536-1.7.334a.693.693 0 00-.977.984c.468.463 1.001.693 1.56.693.684 0 1.407-.345 2.095-1.027l2.226-2.207a2.613 2.613 0 00.775-1.86 2.608 2.608 0 00-.776-1.862z" fill="#2A2D52" fill-opacity=".8"/></svg>
        <b-tooltip
          target="share-bao-video-btn"
          :title="isVideoCopied ? staticText.videoSharedAlertLabel : staticText.shareVideoLabel"
          :delay="{ show: 100, hide: 400 }"
          triggers="hover"
        ></b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import MeetingParticipants from "@/apps/call_history/CallFeedbackComponents/MeetingParticipants"
import VideoControls from "@/apps/call_history/CallFeedbackComponents/VideoControls.vue"

import { mapGetters, mapActions } from "vuex"
export default {
  name: "BaoVideoPlayer",
  components: {
    MeetingParticipants,
    VideoControls
  },
  data () {
    return {
      meetingParticipants: [],
      animationFrameId: null,
      isVideoCopied: false,
      staticTextDefault: {
        videoSharedAlertLabel: "Link copied",
        shareVideoLabel: "Copy Link",
      }
    }
  },
  methods: {
    shareBaoVideo(){
      this.$copyText(window.location.href).then(async () => {
        this.isVideoCopied = true
        await this.sleep(2000)
        this.isVideoCopied = false
      })
    },
    handleTimeUpdate () {
      const currentTime = Math.floor(this.$refs.meetingVideo.currentTime)
      this.$emit("video-time-changed", currentTime)
    },
    startUpdatingTime () {
      const update = () => {
        this.handleTimeUpdate()
        this.animationFrameId = requestAnimationFrame(update)
      }
      update()
    },
    stopUpdatingTime () {
      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId)
        this.animationFrameId = null
      }
    },
    seekTo (timeInSeconds) {
      this.$refs.meetingVideo.currentTime = timeInSeconds
    }
  },
  computed: {
    ...mapGetters({
      videoUrl: "callVideoStore/getVideoUrl"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  beforeDestroy () {
    this.stopUpdatingTime()
  }
}
</script>

<style scoped>

.video-container {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
}

.video-player {
  width: 100% !important;
  border-radius: 16px 16px 0 0;
  max-height: 580px;
}

.video-player::-webkit-media-controls-play-button,
.video-player::-webkit-media-controls-current-time-display,
.video-player::-webkit-media-controls-time-remaining-display{
    display: none !important;
}

.top-right-style{
  position: absolute;
  z-index: 2;
  top: 16px;
  right: 16px;
  display: flex;
}

.share-video {
  cursor: pointer;
  background-color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;

  svg {
    width:20px;
    height:20px
  }
}
</style>
