<template>
  <div class="participants">
    <div
      role="button"
      class="participants-button"
      @click="toggleShowMeetingParticipants"
    >
      <img src="@/assets/svgs/participants.svg" class="team-icon">
    </div>

    <transition name="animate-down">
      <div
        v-if="showMeetingParticipants"
        class="participants-container">

        <div class="participants-header">
          <h3>{{ staticText.participantsLabel }}</h3>

          <div
            role="button"
            class="icon-container"
            @click="showMeetingParticipants=false"
          >
            <i class="fa fa-times fa-md close-icon"></i>
          </div>
        </div>
        <div class="participants-list">
          <div
            v-for="(participant, index) in meetingParticipants"
            :key="index"
            class="participant"
          >
            <div class="participant__avatar">
              <img src="../../../../public/img/icons/user-icon.svg"
                   class="user-icon m-0"
              />
            </div>
            <span class="participant__name ml-2">{{participant.name}}</span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import { mapGetters } from "vuex"

export default {
  name: "MeetingParticipants",
  components: {},
  data () {
    return {
      staticTextDefault: {
        participantsLabel: "Participants"
      },
      showMeetingParticipants: false
    }
  },
  computed: {
    ...mapGetters({
      meetingParticipants: "callVideoStore/getMeetingParticipants"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    toggleShowMeetingParticipants () {
      this.showMeetingParticipants = !this.showMeetingParticipants
    }
  }
}

</script>

<style scoped lang="scss">

.participants {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .participants-button {
    background-color: white;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;

    svg {
      width:20px;
      height:20px
    }
  }

  .participants-container{
    margin-top: 16px;
    background-color: white;
    max-width: 300px;
    padding: 16px;
    border-radius: 16px;

    .participants-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      h3 {
        margin: 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 21.6px;
        color: $slate;
      }

      .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        .close-icon {
          color: #555775;
        }
      }
    }

    .participants-list {
      max-height: 300px;
      overflow-y: auto;

      .participant {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
        &__avatar {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $slate06;
          border-radius: 8px;
          margin-right: 8px;
        }
        &__name {
          color: $slate60;
          width: calc(100% - 40px);
        }
      }
    }
  }
}

</style>
