
class SpeakingBlock {
  constructor (speakerName, startTime, endTime) {
    this.speakerName = speakerName
    this.startTime = startTime
    this.endTime = endTime
  }
}

class SpeakerTimeline {
  constructor () {
    this.totalDuration = 1
    this.speakerTimelineBlocks = []
    this.startBySpeakingBlocks = []
    this.endBySpeakingBlocks = []
  }

  convertTimestampToSeconds (timestamp) {
    const [minutes, seconds] = timestamp.split(":").map(Number)
    return (minutes * 60) + seconds
  }

  addSpeakingBlock (speakerName, startTime, endTime) {
    //
    const newBlock = new SpeakingBlock(speakerName, startTime, endTime)
    this.startBySpeakingBlocks.push(newBlock)
    this.endBySpeakingBlocks.push(newBlock)
  }

  sortBlocks () {
    // sort startBySpeakingBlocks by starttime in ascending order and endBySpeakingBlocks
    // by endTime in ascending order
    this.startBySpeakingBlocks.sort((firstBlock, secondBlock) => firstBlock.startTime - secondBlock.startTime)
    this.endBySpeakingBlocks.sort((firstBlock, secondBlock) => firstBlock.endTime - secondBlock.endTime)
  }

  findTargetBlock (currentTime, targetBlock) {
    const speakingBlocks = targetBlock === "next" ? this.startBySpeakingBlocks : this.endBySpeakingBlocks
    let leftIdx = 0
    let rightIdx = speakingBlocks.length - 1
    let result = null
    currentTime = parseFloat(currentTime.toFixed(5))
    while (leftIdx <= rightIdx) {
      const midIdx = Math.floor((leftIdx + rightIdx) / 2)

      if (targetBlock === "prev") {
        if (speakingBlocks[midIdx].endTime < currentTime) {
          result = speakingBlocks[midIdx]
          leftIdx = midIdx + 1
        } else {
          rightIdx = midIdx - 1
        }
      } else if (targetBlock === "next") {
        if (speakingBlocks[midIdx].startTime > currentTime) {
          result = speakingBlocks[midIdx]
          rightIdx = midIdx - 1
        } else {
          leftIdx = midIdx + 1
        }
      }
    }
    return result
  }

  prepareTimelineBlocks (totalDuration, transcriptData) {
    if (!transcriptData) {
      return
    }
    const lastSpeakingTime = {}
    const timelineBlocksTempData = {}
    transcriptData.forEach(entry => {
      const speakerName = entry.Speaker
      const timestamp = parseFloat(entry.StartTimestamp.toFixed(5))
      const endTime = parseFloat(entry.EndTimestamp.toFixed(5))
      if (speakerName in timelineBlocksTempData) {
        const lastClosingTime = lastSpeakingTime[speakerName]
        if (timestamp > lastClosingTime) {
          timelineBlocksTempData[speakerName].push([(timestamp - lastClosingTime) * 100 / totalDuration, 0])
        }
        timelineBlocksTempData[speakerName].push([(endTime - timestamp) * 100 / totalDuration, 1, timestamp, endTime])
        // add block
        this.addSpeakingBlock(speakerName, timestamp, endTime)
      } else {
        const startTime = 0
        timelineBlocksTempData[speakerName] = [[(timestamp - startTime) * 100 / totalDuration, 0]]
        timelineBlocksTempData[speakerName].push([(endTime - timestamp) * 100 / totalDuration, 1, timestamp, endTime])
        // add block
        this.addSpeakingBlock(speakerName, timestamp, endTime)
      }
      lastSpeakingTime[speakerName] = endTime
    })
    for (const key in timelineBlocksTempData) {
      const endTime = lastSpeakingTime[key]
      timelineBlocksTempData[key].push([(totalDuration - endTime) * 100 / totalDuration, 0])
    }
    // sort startby and endby blocks
    this.sortBlocks()
    this.speakerTimelineBlocks = timelineBlocksTempData
  }

  getSpeakerTimelineSpeakerBlocks () {
    return this.speakerTimelineBlocks
  }

  setup (totalDuration, transcriptData) {
    this.prepareTimelineBlocks(totalDuration, transcriptData)
  }
}

export default SpeakerTimeline
