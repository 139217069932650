<template>
  <div :class="['bao-widget-filter w-100 d-flex inner-label-dropdown-202403211841']">
    <bao-widget-filter-container
      :label="label"
      label-tag="filter-for-user-202012281452"
      :hasError="hasError"
    >
      <vue-multiselect
        id="filter-for-user-202012281452"
        v-model="selectionOption"
        :label="dropdownLabel"
        :track-by="trackBy"
        :multiple="isMultiSelect"
        :close-on-select="closeOnSelect"
        :clear-on-select="clearOnSelect"
        :options="dropdownItems"
        :disabled="disabled"
        :show-labels="false"
        :placeholder="placeholder"
        @input="selectedItem => handleFilterChanged(selectedItem)"
        v-click-outside="handleDropdownClosed"
      >
        <template slot="tag"
                  slot-scope="{ option, remove }"
        >
          <span class="custom__tag d-flex justify-content-between my-1">
            <span class="w-100">
              <span class="option__small">
                {{ option[dropdownLabel] }}
              </span>
            </span>

            <span class="custom__remove"
                  @click.stop="remove(option)"
            >
              <i class="far fa-times-circle"></i>
            </span>
          </span>
        </template>
        <span slot="noOptions">
          {{ staticText.listIsEmpty }}
        </span>
      </vue-multiselect>
    </bao-widget-filter-container>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect"
import BaoWidgetFilterContainer
  from "@/apps/dashboard/BaoWidgetsBaseComponents/configurationComponents/Filter/BaoWidgetFilterContainer.vue"
import vClickOutside from "v-click-outside"

export default {
  name: "InnerLabelDropdown",
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      showDropdownMenu: false,
      hasError: false,
      selectionOption: this.value,
      hasValueChanged: false,
      staticTextDefault: {
        listIsEmpty: "List is empty"
      }
    }
  },
  components: {
    VueMultiselect,
    BaoWidgetFilterContainer
  },
  props: {
    value: {
      type: [Array, Object],
      required: false,
      default: () => null
    },
    label: {
      type: String,
      required: false
    },
    dropdownLabel: {
      type: String,
      required: false,
      default: "text"
    },
    trackBy: {
      type: String,
      required: false,
      default: "question_id"
    },
    placeholder: {
      type: String,
      required: false
    },
    dropdownItems: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    closeOnSelect: {
      type: Boolean,
      required: false,
      default: true
    },
    clearOnSelect: {
      type: Boolean,
      required: false,
      default: true
    },
    isMultiSelect: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    handleFilterChanged (selectedItem) {
      !this.isMultiSelect ? this.$emit("input", selectedItem) : this.hasValueChanged = true
    },
    handleDropdownClosed () {
      if (this.isMultiSelect && this.hasValueChanged) {
        this.$emit("input", this.selectionOption)
        this.hasValueChanged = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
