<template>
  <!-- If there is no audio, we don't need to show anything -->
  <!-- Since audio file url is not available instantly, we need to rely on audio_state (defined in BaoAudio) -->
  <!-- So we just compare against the status 600 (NeverTriggered) and 603 (AudioCreationFailed) -->
  <div
    v-if="isAudioAvailable"
    :class="['call-container ', {'p-3': !isOpenFromCallViewSummary}]"
  >
    <div v-if="showAudioPlayer">
      <ProgressIndicator
        v-if="call.audioLoading"
        :loading-label="staticText.audioLoadingLabel"
      />

      <bao-audio-player
        v-if="call.final_audio_url"
        :call-segment="call"
        :seek-time="seekTime"
        :index="callId"
        :getDisplayTimeStamp="getDisplayTimeStamp"
        @time-update="handleAudioSeek"
      />
    </div>

    <slot name="transcript-tabs-header"></slot>

    <ProcessSpinner v-if="!showAudioPlayer && call.audioLoading"
                    :processingMessage="staticText.transcriptAndSummaryLoadingMessage"/>

    <!-- display transcripts/summary section only after audio becomes available   -->

    <b-tabs
      v-model="activeTabIndex"
      active-nav-item-class="border-left-0 border-top-0 border-right-0 "
      nav-class="no-border d-flex flex-wrap"
    >
      <template
        v-if="showSaveToCrmToggleComputed"
        slot="tabs-end"
      >
        <div
          class="save-to-crm">
          <bao-toggle-button
            id="save-to-crm-toggle"
            :isActive="shouldSaveAISummaryToCrm"
            :label="staticText.saveToCRMLabel"
            :isDisabled="isCallSavedToCrm"
            button-class="minBtn font-14"
            @toggle="toggleSaveAISummaryToCrm"
          ></bao-toggle-button>
        </div>
      </template>
      <!-- transcript summary tab   -->
      <b-tab
        :title="staticText.summaryTabTitle"
        :class="['tab-content border-radius-tl-0', { 'border-tr-0' : showSaveToCrmToggleComputed }]"

      >
        <template slot="title">
          <div class="d-flex align-items-center">
            <div class="mr-1">{{staticText.summaryTabTitle}}</div>
            <bao-copy-button
              v-if="isSummaryAvailable"
              ref="baoCopySummaryButton"
              :shouldInitiateCopyProcess="true"
              @initiate-copy-process="copyTranscriptSummary"
            />
          </div>
        </template>
        <div  v-if="isSummaryAvailable"  class="d-flex flex-column pt-1">
          <div
            class="d-flex flex-row justify-content-between align-items-center mb-3"
          >
            <div
              class="summary-type d-flex align-items-center">
              {{ staticText.summaryTypeLabel }}
            </div>
            <button
              class="regenerate-btn ml-2 h-100"
              @click="regenerateSummary"
              :disabled="summaryLoadingData[currentSummaryTab]"
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-1'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.048 13.75a.4.4 0 00.316-.645L4.51 9.409a.4.4 0 00-.633 0l-2.874 3.697a.4.4 0 00.316.645h1.225c.22 0 .4.18.4.4v.787c0 1.965 1.643 3.563 3.664 3.563h7.329c2.02 0 3.664-1.598 3.664-3.563v-.787a.4.4 0 00-.4-.4h-1.643a.4.4 0 00-.4.4v.787c0 .655-.548 1.188-1.221 1.188H6.607c-.673 0-1.221-.533-1.221-1.188v-.787c0-.22.179-.4.4-.4h1.262zM12.952 6.15a.4.4 0 00-.316.645l2.856 3.697a.4.4 0 00.632 0l2.874-3.696a.4.4 0 00-.316-.646h-1.225a.4.4 0 01-.4-.4v-.787c0-1.965-1.643-3.563-3.664-3.563H6.064C4.044 1.4 2.4 2.998 2.4 4.963v.787c0 .221.18.4.4.4h1.643a.4.4 0 00.4-.4v-.787c0-.654.548-1.188 1.221-1.188h7.329c.673 0 1.221.534 1.221 1.188v.787a.4.4 0 01-.4.4h-1.262z" fill="#555775"/></svg>
              {{staticText.regenerateSummaryLabel}}
            </button>
          </div>
          <div class="d-flex summary-block">
            <div v-for="summaryType in summaryVariants"
                 :key="summaryType.key"
            >
              <button class="mr-2 summary-button"
                      :class="{'active-summary': summaryType.key==currentSummaryTab}"
                      @click="getSummaryData(summaryType.key)">
                {{summaryType.title}}</button>
            </div>
          </div>
        </div>
        <div class="tab-content__container tab-content__summary">
          <div v-if="call.duration && call.duration < 60000" class="mt-4 mb-2 summary-text-color">
            {{ staticText.callTooShortForSummary }}
          </div>
          <ProcessSpinner v-else-if="summaryLoadingData[currentSummaryTab]"
                          :processingMessage="staticText.summaryLoadingLabel"/>
          <div v-else class="mt-4 mb-2 summary-text-color"
          >
            <div ref="transcriptSummary">
              <div
                v-if="call.transcriptSummary && currentSummaryTab==SUMMARY_TYPES.BULLETPOINTS"
              >
                <ul class="bullet-point-summary">
                  <li v-for="(line, index) in getBulletpointsList()" :key="index">{{line}}</li>
                </ul>
              </div>
              <div v-else-if="call.transcriptSummary && currentSummaryTab == SUMMARY_TYPES.TOPIC_BLOCKS">
                <div v-for="(summary, summaryIndex) in call.transcriptSummary[currentSummaryTab]" :key="'summary-' + summaryIndex">
                  <h6>{{ summary.title }} ({{ summary.time_range }})</h6>
                  <ul>
                    <li v-for="(detail, index) in summary.details" :key="'detail-' + summaryIndex + '-' + index">
                      {{ detail }}
                    </li>
                  </ul>
                </div>
              </div>
              <div v-else-if="call.transcriptSummary && currentSummaryTab == SUMMARY_TYPES.ACTION_ITEMS">
                <ul>
                  <li v-for="(summary, summaryIndex) in call.transcriptSummary[currentSummaryTab]" :key="'summary-' + summaryIndex">
                    <h6>{{ summary.title }}</h6>
                    <p>{{ summary.details }}</p>
                  </li>
                </ul>
              </div>
              <div v-else-if="call.transcriptSummary && currentSummaryTab == SUMMARY_TYPES.EMAIL">
                <h6>{{ call.transcriptSummary[currentSummaryTab].subject }}</h6>
                <p>{{ call.transcriptSummary[currentSummaryTab].body }}</p>
              </div>
              <div v-else-if="call.transcriptSummary">
                {{ call.transcriptSummary[currentSummaryTab] || staticText.noSummaryAvailableText }}
              </div>
              <!-- This is a `fail-safe` for UX, in an ideal flow, we should never get here -->
              <div v-else>
                {{ staticText.noSummaryAvailableText }}
              </div>
            </div>
            <div v-if="isSummaryAvailable" class="summary-disclaimer mt-2">
              {{ staticText.summaryDisclaimer }}
            </div>
          </div>
        </div>

      </b-tab>

      <!--   Transcript tab  -->
      <b-tab
        v-if="!isOpenFromCallViewSummary"
        :title="staticText.transcriptTabTitle"
        class="tab-content"
      >
        <template slot="title">
          <div class="d-flex align-items-center">
            <div class="mr-1">{{staticText.transcriptTabTitle}}</div>
            <bao-copy-button
              v-if="isTranscriptAvailable"
              ref="baoCopyTranscriptsButton"
              :shouldInitiateCopyProcess="true"
              @initiate-copy-process="copyTranscripts"
            />
          </div>
        </template>
        <div class="tab-content__container">
          <ProcessSpinner v-if="isTranscriptLoading"
                          :processingMessage="transcriptLoadingLabel"/>
          <bao-audio-transcript
            v-if="!isTranscriptLoading && call.transcription"
            :call-id="callId"
            :current-time="currentTime"
            :getDisplayTimeStamp="getDisplayTimeStamp"
            @text-clicked="handleTextClick"
            ref="baoAudioTranscript"
          ></bao-audio-transcript>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import BaoAudioPlayer from "@/apps/call_history/CallFeedbackComponents/BaoAudioPlayer"
import BaoAudioTranscript from "@/apps/call_history/CallFeedbackComponents/BaoAudioTranscript"
import moment from "moment/moment"
import { mapActions, mapGetters } from "vuex"
import ProgressIndicator from "@/apps/base/ProgressIndicator"
import ProcessSpinner from "@/apps/base/ProcessSpinner"
import BaoToggleButton from "@/apps/base/BaoToggleButton"
import BaoCopyButton from "@/apps/base/BaoCopyButton"
import { SUMMARY_TYPES } from "@/apps/call_history/CallFeedbackComponents/constants"
import { setCookie, getCookie } from "@/utils/utils"

export default {
  name: "BaoAudio",
  components: {
    BaoAudioPlayer,
    BaoAudioTranscript,
    ProgressIndicator,
    ProcessSpinner,
    BaoToggleButton,
    BaoCopyButton
  },
  data () {
    return {
      activeTabIndex: 0,
      fetchAudio: false,
      fetchTranscripts: false,
      fetchSummary: false,
      currentTime: 0,
      seekTime: 0,
      staticTextDefault: {
        transcriptTabTitle: "AI Transcript",
        summaryTabTitle: "AI Summary",
        transcriptLoadingLabel: "Requesting the transcript…",
        requestingNewTranscriptLabel: "Requesting a new transcript…",
        audioLoadingLabel: "Requesting the audio…",
        summaryLoadingLabel: "Requesting the summary…",
        noSummaryAvailableText: "Sadly no summary available for this call.",
        callTooShortForSummary: "Summaries are only available for calls longer than 60 seconds",
        transcriptAndSummaryLoadingMessage: "The transcript and a short summary of your call are being processed right now. Depending on the length of your call, this can take a little bit longer.",
        saveToCRMLabel: "Save to CRM",
        regenerateSummaryLabel: "Regenerate",
        summaryTypeLabel: "Summary Type:",
        summaryDisclaimer: "This summary was computer generated and might contain errors.",
        defaultSummaryLabel: "Default",
        shortSummaryLabel: "Short",
        longSummaryLabel: "Long",
        bulletpointsSummaryLabel: "Bulletpoints",
        topicBlocksSummaryLabel: "Topic Blocks",
        actionItemsSummaryLabel: "Action Items",
        emailSummaryLabel: "Email"
      },
      SUMMARY_TYPES,
      currentSummaryTab: "default"
    }
  },
  props: {
    callId: {
      required: true
    },
    showAudioPlayer: {
      type: Boolean,
      default: true
    },
    isOpenFromCallViewSummary: {
      type: Boolean,
      default: false
    },
    shouldSaveAISummaryToCrm: {
      type: Boolean,
      default: true
    },
    showSaveToCrmToggle: {
      type: Boolean
    },
    isCallSavedToCrm: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    async call (val) {
      if (val && this.triggerAudio && !this.fetchAudio) {
        this.getAudioData({ callId: this.callId })
        this.fetchAudio = true
      }
      if (val && !this.fetchTranscripts) {
        this.getAudioTranscripts({ callId: this.callId })
        this.fetchTranscripts = true
      }
      if (val && !val.transcriptSummary && this.triggerSummary) {
        if (!this.summaryLoadingData[this.currentSummaryTab] && !this.fetchSummary) {
          await this.getAudioTranscriptSummary({ callId: this.callId, summaryFormat: this.currentSummaryTab })
          this.fetchSummary = true
          this.setCurrentSummary()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getCall: "callAudioStore/getCall",
      summaryLoadingData: "callAudioStore/getSummaryDataLoading",
      triggerSummary: "callAudioStore/canTriggerSummary"
    }),
    isTranscriptAvailable () {
      return !!this.call.transcription && !!this.call.transcription.response_data && !!this.call.transcription.response_data.length
    },
    isSummaryAvailable () {
      return this.triggerSummary && !!this.call.transcriptSummary && !!this.call.transcriptSummary[this.currentSummaryTab]
    },
    isAudioAvailable () {
      return this.call && this.call.final_audio_state && this.call.final_audio_state !== 600 && this.call.final_audio_state !== 603
    },
    call () {
      return this.getCall
    },
    triggerAudio () {
      return !!this.call &&
        !this.call.final_audio_url
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    transcriptLoadingLabel () {
      return this.call.transcriptLoading ? this.staticText.transcriptLoadingLabel : this.staticText.requestingNewTranscriptLabel
    },
    isTranscriptLoading () {
      return this.call.transcriptLoading || this.call.newTranscriptLoading
    },
    summaryVariants () {
      return [
        {
          title: this.staticText.defaultSummaryLabel,
          key: "default"
        },
        {
          title: this.staticText.shortSummaryLabel,
          key: "short"
        },
        {
          title: this.staticText.longSummaryLabel,
          key: "long"
        },
        {
          title: this.staticText.bulletpointsSummaryLabel,
          key: "bulletpoints"
        },
        {
          title: this.staticText.topicBlocksSummaryLabel,
          key: "topic_blocks"
        },
        {
          title: this.staticText.actionItemsSummaryLabel,
          key: "action_items"
        }
        // {
        //   title: this.staticText.emailSummaryLabel,
        //   key: "email"
        // }
      ]
    },
    showSaveToCrmToggleComputed () {
      return this.showSaveToCrmToggle && this.isSummaryAvailable && this.activeTabIndex === 0 // Index 0 is for summary tab
    }
  },
  async mounted () {
    this.setCurrentSummaryTabFromCookie()
    // reset the current summary if set already
    this.setCurrentSummaryText({ summaryText: "" })
    this.setSummaryTab({ summaryTab: this.currentSummaryTab })
    if (this.callId) {
      await this.getCallById({ callId: this.callId })
    }
    this.setCurrentSummary()
  },
  methods: {
    ...mapActions({
      getCallById: "callAudioStore/getCallById",
      getAudioData: "callAudioStore/getBaoAudioSegments",
      getAudioTranscripts: "callAudioStore/requestAudioTranscripts",
      getAudioTranscriptSummary: "callAudioStore/requestTranscriptSummary",
      setSummaryTab: "callAudioStore/setSummaryTab",
      setCurrentSummaryText: "callSummaryStore/setCurrentSummaryText"
    }),
    setCurrentSummaryTabFromCookie () {
      const favoriteSummaryFormat = getCookie("favoriteSummaryFormat")
      this.currentSummaryTab = favoriteSummaryFormat || "default"
    },
    async setCurrentSummary () {
      await this.sleep(100)
      const transcriptSummary = this.$refs.transcriptSummary
      const summaryText = transcriptSummary ? transcriptSummary.innerText : ""
      this.setCurrentSummaryText({ summaryText })
    },
    copyTranscripts () {
      const textToCopy = this.$refs.baoAudioTranscript.getTranscriptsToCopy()
      this.$refs.baoCopyTranscriptsButton.copyText(textToCopy)
    },
    async copyTranscriptSummary () {
      const transcriptSummary = this.$refs.transcriptSummary

      if (!transcriptSummary) {
        console.error("Transcript summary element not found")
        return
      }

      try {
        const range = document.createRange()
        range.selectNodeContents(transcriptSummary)
        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)

        const fragment = range.cloneContents()
        const div = document.createElement("div")
        div.appendChild(fragment)

        const { ClipboardItem } = window
        await navigator.clipboard.write([
          new ClipboardItem({
            "text/html": new Blob([div.innerHTML], { type: "text/html" }),
            "text/plain": new Blob([transcriptSummary.innerText], { type: "text/plain" })
          })
        ])
        this.$refs.baoCopySummaryButton.flashCopiedMessage() // Display the "copied" popup
      } catch (err) {
        console.error("Failed to copy content with format", err)
        const textToCopy = this.$refs.transcriptSummary.innerText
        this.$refs.baoCopySummaryButton.copyText(textToCopy)
      } finally {
        const selection = window.getSelection()
        selection.removeAllRanges()
      }
    },
    getBulletpointsList () {
      // generate an array of bulletpoint from bulletpoint summary
      if (this.call.transcriptSummary) {
        if (Array.isArray(this.call.transcriptSummary[this.currentSummaryTab])) {
          return this.call.transcriptSummary[this.currentSummaryTab]
        } else {
          return this.call.transcriptSummary[this.currentSummaryTab].replace(/-/g, "").replace(/\n\n/g, "\n").split("\n")
        }
      }
      return ""
    },
    toggleSaveAISummaryToCrm () {
      this.$emit("toggleSaveAISummaryToCrm")
    },
    handleAudioSeek (seekTime) {
      this.currentTime = seekTime * 1000 // seconds to milliseconds
    },
    handleTextClick (startTime) {
      this.seekTime = startTime / 1000 // milliseconds to seconds
    },
    getDisplayTimeStamp (milliseconds) {
      const timeInSeconds = Math.ceil(milliseconds / 1000)
      return moment()
        .startOf("day")
        .seconds(timeInSeconds)
        .format(timeInSeconds > 3600 ? "HH:mm:ss" : "mm:ss")
    },
    async getSummaryData (summaryFormat) {
      // Set the "favorite" summary format to clicked tab
      setCookie("favoriteSummaryFormat", summaryFormat)

      // on summary tab change, get the summary data.
      // if it's available in vuex store return else call api to get
      this.currentSummaryTab = summaryFormat
      this.setSummaryTab({ summaryTab: this.currentSummaryTab })
      const transcriptSummary = this.call.transcriptSummary
      // set the current summary
      this.setCurrentSummary()
      if (transcriptSummary && !!transcriptSummary[this.currentSummaryTab]) {
        return
      }
      if (!this.summaryLoadingData[this.currentSummaryTab] && this.triggerSummary) {
        await this.getAudioTranscriptSummary({ callId: this.callId, summaryFormat })
      }
      // set the current summary
      this.setCurrentSummary()
    },
    async regenerateSummary () {
      if (this.triggerSummary) {
        await this.getAudioTranscriptSummary({ callId: this.callId, summaryFormat: this.currentSummaryTab, regenerate: true })
      }
    }
  }

}
</script>

<style scoped lang="scss">

.tab-content {
  background: #FFF;
  border-radius: 16px;
  padding: 16px 16px 16px 16px;

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__summary {
    max-height: 300px;
    overflow-y: scroll;
  }

  .border-radius-tl-0 {
    border-radius: 0 16px 16px;
    @include media-breakpoint-down(xs) {
      &.border-tr-0 {
        border-radius: 0 0 16px 16px;
      }
    }
  }
}

.call-container  {
  :deep(.nav-link.active) {
    border-radius: 16px 16px 0 0;
  }

}

.summary-block{
  overflow-x: auto;
}

.summary-button {
  padding: 9px 10px;
  border-radius: 12px;
  line-height:14px;
  justify-content: center;
  font-size: 12px;
  align-items: center;
  background-color: $slate06;
  outline: none;
  border: none;
  color: $slate80;
  height: fit-content;
  }

.active-summary{
  background-color: #FF56321F !important;
  color: #FF5632 !important;
}

.summary-type {
  color: $slate40;
  font-size: 12px;
}

.regenerate-btn {
  //over write some styles of regenerate button
  padding: 8px 10px;
  line-height: 14px;
  svg {
    height: 16px;
    width: 16px;
  }
}
.summary-text-color {
  color: #00000080;
}
.bullet-point-summary{
  padding-left: 16px;
  margin-bottom: 0;
}
.summary-disclaimer{
  color: $slate40;
  font-size: 12px;
}

.save-to-crm {
  display: flex;
  margin-left: auto;
  @include media-breakpoint-down(xs) {
    padding: 10px 0 10px 16px;
    width: 100%;
    background-color: white;
    border-radius: 0 16px 0 0;
  }
}

</style>
